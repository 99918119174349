const prepareEio = '/research-plan-preparation/prepare-eio/'
export const prepareEioList = prepareEio + 'list'
export const prepareEioStore = prepareEio + 'store'
export const prepareEioUpdate = prepareEio + 'update'
export const prepareEioTogle = prepareEio + 'toggle-status'
export const prepareEioShow = prepareEio + 'show'

const assignTaskCoordinator = '/research-plan-preparation/assign-task-coordinator/'
export const assignTaskCoordinatorList = assignTaskCoordinator + 'list'
export const assignTaskCoordinatorStore = assignTaskCoordinator + 'store'
export const assignTaskCoordinatorUpdate = assignTaskCoordinator + 'update'
export const assignTaskCoordinatorTogle = assignTaskCoordinator + 'toggle-status'
export const assignTaskCoordinatorShow = assignTaskCoordinator + 'show'

const prepareRfp = '/research-plan-preparation/prepare-rfp/'
export const prepareRfpList = prepareRfp + 'list'
export const prepareRfpStore = prepareRfp + 'store'
export const prepareRfpUpdate = prepareRfp + 'update'
export const prepareRfpChangeStatus = prepareRfp + 'change-status'
export const prepareRfpShow = prepareRfp + 'show'
export const prepareRfpDropdownList = prepareRfp + 'rfp-list'

export const userListByDesignWise = '/user-management/user/list-by-designation'

const yearlyActionPlan = '/research-plan-preparation/yearly-action-plans/'
export const yearlyActionPlanList = yearlyActionPlan + 'list'
export const yearlyActionPlanStore = yearlyActionPlan + 'store'
export const yearlyActionPlanUpdate = yearlyActionPlan + 'update'
export const yearlyActionPlanChangeStatus = yearlyActionPlan + 'change-status'
export const yearlyActionPlanForward = yearlyActionPlan + 'forward'
export const yearlyActionPlanForwardedList = yearlyActionPlan + 'forwarded-list'
export const yearlyActionPlanApprove = yearlyActionPlan + 'approve'
export const yearlyActionPlanReject = yearlyActionPlan + 'reject'
export const yearlyActionPlanApprovedList = yearlyActionPlan + 'approved-list'
